<template>
    <section class="section-hero section-shaped my-0">
        <div v-if="hero_background || hero_background_mobile" class="shape shape-primary" :style="hero_background_style"></div>
        <div v-else class="shape shape-primary"></div>
        <div class="container shape-container d-flex align-items-center">
            <div class="col px-0">
                <div class="row justify-content-center align-items-center">
                    <div class="colo-xs-12 col-lg-8 text-left">
                        <img src="img/brand/HealthView-Home-Health-Hospice_logo.png" style="width: 800px;" class="img-fluid"  alt="HealthView Home Health &amp; Hospice logo">
                        <h2 class="d-none d-md-block display-3">Our team is your team.<br/>We always have our eyes on you.</h2>
                        <h2 class="d-block d-md-none h5 font-weight-bold">Our team is your team.<br/>We always have our eyes on you.</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
  name: "hero",
  props: {
    hero_background: String,
    hero_background_mobile: String,
    hero_background_mobile_hd: String,
    logo: String,
    logo_width: String
  },
  data: () => ({
    windowWidth: 0,
    windowHeight: 0
  }),
  mounted(){
    this.$nextTick(() =>{
      window.addEventListener('resize', this.getWindowWidth)
      window.addEventListener('resize', this.getWindowHeight)
      this.getWindowWidth()
      this.getWindowHeight()
    })
  },
  methods: {
    getWindowWidth(event){
      this.windowWidth = document.documentElement.clientWidth
    },
    getWindowHeight(){
      this.windowHeight = document.documentElement.clientHeight
    }
  },
  computed: {
    hero_background_style(){
      var scale = window.devicePixelRatio;
      let desktop = `background-image: url('${this.hero_background}'); min-height:100%; background-attachment: fixed; background-repeat: no-repeat; background-size: cover; -moz-background-size: cover;`
      let mobile = `background-image: -webkit-image-set(url('${this.hero_background_mobile}') 1x,url('${this.hero_background_mobile_hd}') 2x); min-height:100%; background-repeat: no-repeat; background-size: cover; -moz-background-size: cover;` //no fixed for iphone to work
      // let desktop = `background-image: url('${this.hero_background}'); min-height:100%; background-attachment: fixed; background-repeat: no-repeat; background-size: cover; -moz-background-size: cover;`
      // let mobile = `background-image: url('${this.hero_background_mobile}'); min-height:100%; background-attachment: fixed; background-repeat: no-repeat; background-size: cover; -moz-background-size: cover;`
      if (this.windowWidth < 813) {
        return mobile
      } else {
        return desktop
      }
    },
    ratio(){
      var scale = window.devicePixelRatio
      return scale
    }
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.getWindowWidth);
    window.removeEventListener('resize',this.getWindowHeight);
  }

};
</script>
