<template>
        <section class="section">
          <h2 class="text-center pb-5">Learn more about our services</h2>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-medkit" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h3 class="text-primary text-uppercase">Home Health</h3>
                                    <p class="description mt-3">Medical care provided at home where a patient can feel more comfortable and secure.</p>
                                    <router-link to="/services#homehealth" class="btn btn-primary mt-4">Learn More</router-link>
                                </card>
                            </div>
                            <!-- <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-leaf" type="success" rounded class="mb-4">
                                    </icon>
                                    <h3 class="text-success text-uppercase">Hospice</h3>
                                    <p class="description mt-3">Special care for maintaining and improving quality of life for those with terminal illnesses.</p>
                                    <router-link to="/services#hospice" class="btn btn-success mt-4">Learn More</router-link>
                                </card>
                            </div> -->
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-leaf" type="success" rounded class="mb-4">
                                    </icon>
                                    <h3 class="text-success text-uppercase">Rehabilitation</h3>
                                    <p class="description mt-3">Available to assist with emotional support, counseling, and help you identify resources.</p>
                                    <router-link to="/services#rehabilitation_services" class="btn btn-success mt-4">Learn More</router-link>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-user-md" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h3 class="text-primary text-uppercase">Skilled Nurses</h3>
                                    <p class="description mt-3">Visits by skilled nurses available for a range of services from injections to monitoring chemotherapy drugs.</p>
                                    <router-link to="/services#services" class="btn btn-primary mt-4">Learn More</router-link>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
</template>
  
<script>
  export default {
    name: 'services_cards',
  }
</script>