import Vue from "vue";
import Router from "vue-router";
import Meta from 'vue-meta';

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

// import AppHeader from "./layout/AppHeader";
import HVHeader from "./layout/HVHeader";
import HVFooter from "./layout/HVFooter";

import Home from "./views/Home.vue";

const About = () => import(/* webpackChunkName: "about" */ './views/About.vue')
const ServiceAreas = () => import(/* webpackChunkName: "about" */ './views/ServiceAreas.vue')
const Services = () => import(/* webpackChunkName: "services" */ './views/Services.vue')
const Contact = () => import(/* webpackChunkName: "pages-group" */ './views/Contact.vue')

const FAQ = () => import(/* webpackChunkName: "faq" */ './views/FAQ.vue')

const Employment = () => import(/* webpackChunkName: "employment" */ './views/Employment/Employment.vue')
const Application = () => import(/* webpackChunkName: "application" */ './views/Employment/Application.vue')
const Jobs = () => import(/* webpackChunkName: "jobs" */ './views/Employment/jobs/Jobs.vue')
const Job = () => import(/* webpackChunkName: "job" */ './views/Employment/jobs/Job.vue')


import Privacy from "./views/Privacy.vue";
import NotFound from "./views/NotFound.vue";

Vue.use(Router);
Vue.use(Meta);

let router = new Router({
  mode: "history",
  linkExactActiveClass: "active",
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      components: {
        header: HVHeader,
        default: Home,
        footer: HVFooter
      }
    },
    {
      path: "/about",
      name: "about",
      components: {
        header: HVHeader,
        default: About,
        footer: HVFooter
      }
    },
    {
      path: "/service-areas",
      name: "service-areas",
      components: {
        header: HVHeader,
        default: ServiceAreas,
        footer: HVFooter
      }
    },
    {
      path: "/contact",
      name: "contact",
      components: {
        header: HVHeader,
        default: Contact,
        footer: HVFooter
      }
    },
    {
      path: "/services",
      name: "services",
      components: {
        header: HVHeader,
        default: Services,
        footer: HVFooter
      }
    },
    {
      path: "/faq",
      name: "faq",
      components: {
        header: HVHeader,
        default: FAQ,
        footer: HVFooter
      }
    },
    {
      path: "/privacy",
      name: "privacy",
      components: {
        header: HVHeader,
        default: Privacy,
        footer: HVFooter
      }
    },
    {
      path:"/employment",
      name: "employment",
      components: {
        header: HVHeader,
        default: Employment,
        footer: HVFooter
      }
    },
    {
      path:"/employment/application",
      name: "application",
      components: {
        header: HVHeader,
        default: Application,
        footer: HVFooter
      }
    },
    {
      path:"/jobs",
      name: "jobs",
      components: {
        header: HVHeader,
        default: Jobs,
        footer: HVFooter
      }
    },
    {
      path:"/job/:id",
      name: "job-single",
      components: {
        header: HVHeader,
        default: Job,
        footer: HVFooter
      }
    },
    {
      path: '*',
      component: NotFound
    }
  ]
});

export default router;
