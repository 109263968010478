<template>
     <div class="d-lg-block col-md-6 text-center">
      <span class="h6 d-block font-weight-bold">
        {{ agency.name }}
      </span>
      {{ agency.address }}<br>
      <template v-if="agency.address_2">
        {{ agency.address_2 }}<br>
      </template>
      {{ agency.city }}, {{ agency.state }} {{ agency.postal_code }}
      <br />Office:
      <a :href="`tel:1-${agency.phone}`">{{ phoneFormatted(agency.phone) }}</a> | Fax: {{ phoneFormatted(agency.fax) }}
    </div>
</template>

<script>
export default {
  props: {
    agency: {
      type: Object
    }
  },
  data () {
    return {
    }
  },
  methods: {
    phoneFormatted (phone) {
      if (!phone)
        return ''
      return phone
        .replace(/-/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
    }
  }
}
</script>