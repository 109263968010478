<template>
<div class="position-relative">
  <section class="section-small section-shaped my-0">
    <!-- <div class="shape shape-primary"></div> -->
    <div class="shape shape-healthview"></div>
    <div class="container shape-container d-flex align-items-center">
      <div class="col px-0">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-7 text-center">
            <h1 class="display-2 text-white">{{ page_title }}</h1>
            <img v-if="hero_image" :src="hero_image" style="width: 200px;" class="img-fluid" alt="HealthView Home Health &amp; Hospice background image">
            <p v-if="leadline" v-html="leadline" class="lead text-white mt-1 mb-5"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>
<script>
  export default {
    name: 'hero-small',
    props: {
      page_title: {
        type: String,
        default: ''
      },
      leadline: {
        type: String
      },
      hero_image: {
        type: String
      }
    }
  };
</script>
<style>
</style>