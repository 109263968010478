<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img
            src="img/brand/HealthView-Home-Health-Hospice_logo.png"
            class="img-fluid"
            alt="HealthView Home Health &amp; Hospice logo"
          />
          <h4 class="h5 mb-0 text-center text-md-left font-weight-light">We are committed to providing high quality, comprehensive health care essential in the rehabilitation and recovery of our patients.</h4>
        </div>
        <div class="col-md-6">
          <card
            shadow
            class="shadow-lg--hover mt-4"
          >
            <div class="d-flex">
              <div>
                <icon
                  name="fas fa-question"
                  type="primary"
                  color="white"
                  rounded
                  class="mb-4"
                ></icon>
              </div>
              <div class="pl-4">
                <h5 class="title text-primary">Have additional questions?</h5>
                <p>
                  Search
                  <router-link to="/faq">frequently asked questions</router-link>or
                  <router-link to="/contact">contact us</router-link>.
                </p>
                <!-- Buttons Large => larger -->
                <div class="d-none d-lg-block">
                  <router-link
                    class="btn btn-info"
                    to="/faq"
                  >FAQ</router-link>
                  <router-link
                    class="btn btn-info"
                    to="/contact"
                  >Contact Us</router-link>
                </div>
                <!-- Buttons Tablet => smaller -->
                <div class="d-block d-lg-none">
                  <router-link
                    class="btn btn-info btn-block"
                    to="/faq"
                  >FAQ</router-link>
                  <router-link
                    class="mt-2 btn btn-info btn-block"
                    to="/contact"
                  >Contact Us</router-link>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <hr />
      <div>
        <h5 class="h5 text-center font-weight-bold">HealthView Home Health &amp; Hospice</h5>
        <div class="row">
          <FooterAddress
            :agency="nashAgency"
          />
          <FooterAddress
            class="mt-3"
            :agency="caswellAgency"
          />
        </div>
      </div>
      <div class="text-center mt-2">
        <router-link
          class="btn btn-outline-secondary"
          to="/service-areas"
        >Service Areas</router-link>
      </div>
      <hr />
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-6">
          <div class="copyright">
            &copy; {{ year }}
            <router-link to="/">HealthView Home Health &amp; Hospice</router-link> v.{{ version }}
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-footer justify-content-end">
            <li class="nav-item">
              <router-link
                to="/privacy"
                class="nav-link"
              >Privacy Policy</router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/contact"
                class="nav-link"
              >Contact Us</router-link>
            </li>
            <li class="nav-item">
              <router-link
                to="/employment"
                class="nav-link"
              >Employment</router-link>
            </li>
            <li class="nav-item">
              <a
                href="http://www.healthviewcp.com/"
                class="nav-link"
                target="_blank"
              >HealthView Capital</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterAddress from '../components/FooterAddress.vue'
export default {
  components: {
    FooterAddress
  },
  data () {
    return {
      nashAgency: {
        address: '130 S Franklin St, Suite 401',
        address_2: 'PO Box 7757',
        city: 'Rocky Mount',
        fax: '252-210-2908',
        name: 'Nash County Agency',
        phone: '252-462-2687',
        postal_code: '27804',
        state: 'NC'
      },
      caswellAgency: {
        address: '1060 86 HWY N ',
        address_2: null,
        city: 'Yanceyville',
        fax: '336-694-7450',
        name: 'Caswell County Agency',
        phone: '336-694-9592',
        postal_code: '27379',
        state: 'NC'
      }
    }
  },
  computed: {
    year () {
      let date = new Date()
      return date.getFullYear()
    },
    version () {
      return process.env.VUE_APP_VERSION
    }
  }
}
</script>
