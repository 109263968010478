<template>
    <div>
      <Hero 
        :hero_background="hero_background" 
        :hero_background_mobile="hero_background_mobile"
        :hero_background_mobile_hd="hero_background_mobile_hd" />
        <section class="section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xs-12 col-sm-9 col-md-7 col-lg-6 col-xl-5">
                <div class="card bg-info shadow border-0 mb-4">
                  <!-- <img v-lazy="'img/theme/home_visit.jpg'" class="card-img-top"> -->
                  <picture class="card-img-top">
                    <source srcset="img/theme/home_visit.jpg 1x, img/theme/home_visit@2x.jpg 2x"/>
                    <img class="card-img-top" v-lazy="'img/theme/home_visit.jpg'" alt="Coming for a home health visit" title="Home Health Visit - HealthView" />
                  </picture>
                  <blockquote class="card-blockquote">
                    <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95" class="svg-bg">
                      <polygon points="0,52 583,95 0,95" class="fill-info"></polygon>
                      <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-info"></polygon>
                    </svg>
                      <h3 class="d-none d-md-block h1 text-white text-center">
                        For referrals call<br/>
                        <a class="text-white display-2" href="tel:1-252-462-2687">(252) 462-2687</a>
                      </h3>
                      <h3 class="d-block d-md-none h3 text-white text-center">
                        For referrals call<br/>
                        <a class="text-white font-weight-bold" href="tel:1-252-462-2687">(252) 462-2687</a>
                      </h3>
                      <!-- <h3 class="d-block d-md-none text-white text-center">For referrals call<br/><a href="tel:1-252-462-2687">(252) 462-2687</a> </h3> -->
                  </blockquote>
                </div>
              </div>
              <div class="col-xs-12 col-md-5 col-lg-4">
                <h3 class="h4">HealthView Home Health &amp; Hospice</h3>
                <p>We think of our patients as family. As a locally owned and operated agency, we provide excellent in-home care to you… <strong>our neighbors</strong>.</p>
                <p>We are committed to providing high quality, comprehensive health care essential in the rehabilitation and recovery of our patients.</p>
                <div class="mt-4 text-center">
                  <router-link to="/contact" class="btn btn-info btn-block btn-lg">Contact Us</router-link>
                </div>
              </div>
            </div>
            
            
            <!-- <div class="row justify-content-center">
              <div class="col-xs-12 col-sm-9 col-lg-7">
                <card shadow class="shadow-lg--hover bg-success">
                  <div class="d-flex">
                    <div class="bg-success">
                      <i class="d-none d-md-block fas fa-phone fa-6x text-primary mt-md"></i>
                      <i class="d-block d-md-none fas fa-phone fa-4x text-primary mt-md"></i>
                    </div>
                    <div class="pl-4">
                      <h3 class="d-none d-md-block h1 text-primary">For referrals call<br/><a href="tel:1-252-462-2687">(252) 462-2687</a> </h3>
                      <h3 class="d-block d-md-none text-primary">For referrals call<br/><a href="tel:1-252-462-2687">(252) 462-2687</a> </h3>
                      <hr>
                      <div class="text-center">
                        <router-link class="btn btn-info" to="/contact">Contact Us</router-link>
                      </div>
                    </div>
                  </div>
                </card>
                <p class="lead">We are committed to providing high quality, comprehensive health care essential in the rehabilitation and recovery of our patients.</p>
              </div>
            </div> -->
            
          </div>
        </section>
        <hr>
        <ServicesCards />
    </div>
</template>
<script>
import Hero from "./components/Hero";
import ServicesCards from "./components/ServicesCards";
export default {
  name: "components",
  metaInfo: {
    meta: [
      {
        'description': 'HealthView Home Health & Hospice provides home health care to ten counties in North Carolina including Nash, Edgecombe and Wake. we are also committed to providing exceptional care to previous patients of Nash Home Health.'
      }
    ]
  },
  components: {
    Hero,
    ServicesCards,
  },
  data: () => ({
    hero_background: 'img/bg/HealthView_HomeHealth_bg1.jpg',
    hero_background_mobile: 'img/bg/HealthView_HomeHealth_bg1_mobile.jpg',
    hero_background_mobile_hd: 'img/bg/HealthView_HomeHealth_bg1_mobile@2x.jpg',
  })
};
</script>

<style>
  .btn.call_now {
    font-size:.9em;
  }
</style>
