<template>
  <header class="header-global">
    <!-- TODO: add mobile call button for emergencies  -->
    <base-nav class="navbar-main bg-primary" type="" effect="dark" expand>
      <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
        <img src="img/brand/HealthView_Home-Health-Hospice-draftWhite.png" alt="HealthView Home Health &amp; Hospice logo">
      </router-link>

      <div class="row" slot="content-header" slot-scope="{closeMenu}">
        <div class="col-10 collapse-brand">
          <router-link to="/">
            <img src="img/brand/HealthView-Home-Health-Hospice_logo.png" alt="HealthView Home Health &amp; Hospice logo">
          </router-link>
        </div>
        <div class="col-2 collapse-close">
          <close-button @click="closeMenu"></close-button>
        </div>
      </div>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center" slot-scope="{closeMenu}">
        <li v-for="i in menu_items" :key="i.title" class="nav-item" @click="closeMenu">
          <router-link class="nav-link" :to="i.route" role="button">
            <span class="nav-link-inner--text">{{ i.title }}</span>
          </router-link>
        </li>
        <!-- <li class="nav-item" @click="closeMenu">
          <router-link class="nav-link" to="/employment" role="button">
            <span class="nav-link-inner--text">Employment</span>
          </router-link>
        </li> -->
      </ul>
    </base-nav>
  </header>
</template>
<script>
  import BaseNav from "@/components/BaseNav";
  import CloseButton from "@/components/CloseButton";

  export default {
    name: 'HVHeader',
    components: {
      BaseNav,
      CloseButton
    },
    data: () => ({
      menu_items: [
        {
          title: 'About',
          route: '/about'
        },
        {
          title: 'Service Areas',
          route: '/service-areas'
        },
        {
          title: 'Services',
          route: '/services'
        },
        {
          title: 'FAQ',
          route: '/faq'
        },
        {
          title: 'Contact',
          route: '/contact'
        },
      ]
    })
  };  
</script>
<style>
</style>