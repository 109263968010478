import 'core-js/stable'
import Vue from "vue";
import VueAnalytics from 'vue-analytics';
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";

Vue.use(VueAnalytics, {
  id: 'UA-124594988-1',
  router,
  checkDuplicatedScript: true
})

Vue.config.productionTip = false;
Vue.use(Argon);
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
