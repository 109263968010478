<template>
    <section class="section-hero section-shaped my-0">
        <div class="shape shape-primary"></div>
        <div class="container shape-container d-flex align-items-center">
            <div class="col px-0">
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-7 text-center">
                        <h1 class="display-1">404</h1>
                        <h2 class="display-3">Sorry that page was not found</h2>
                        <router-link to="/" class="btn btn-primary mt-3 btn-lg btn-block">Go to Home Page</router-link>
                        <img src="img/brand/HealthView-Home-Health-Hospice_logo.png" style="width: 800px;" class="img-fluid" alt="HealthView Home Health &amp; Hospice logo">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
  name: "not_found",
  data: () => ({

  })
};
</script>
